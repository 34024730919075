import React from 'react';
import Slider from 'react-slick';
import './MainCategories.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MainCategories = () => {
  const productCategories = [
    { name: 'Accommodation', subCategories: ['Hotel', 'Motel', 'Guest House'], icon: 'fa-hotel' },
    { name: 'Boutique', subCategories: ['Clothing', 'Accessories', 'Handmade'], icon: 'fa-shopping-bag' },
    { name: 'Construction', subCategories: ['Residential', 'Commercial', 'Renovation'], icon: 'fa-tools' },
    { name: 'Home Help', subCategories: ['Cleaning', 'Cooking', 'Babysitting'], icon: 'fa-home' },
    { name: 'Jewelry', subCategories: ['Gold', 'Silver', 'Diamond'], icon: 'fa-gem' },
    { name: 'Food Truck', subCategories: ['Mexican', 'Italian', 'Indian'], icon: 'fa-truck' },
    { name: 'Restaurants', subCategories: ['Fast Food', 'Fine Dining', 'Casual Dining'], icon: 'fa-utensils' }
  ];

  const serviceCategories = [
    { name: 'Realtor', subCategories: ['Buying', 'Selling', 'Renting'], icon: 'fa-building' },
    { name: 'Transportation', subCategories: ['Taxi', 'Bus', 'Train'], icon: 'fa-taxi' },
    { name: 'Legal', subCategories: ['Corporate', 'Criminal', 'Civil'], icon: 'fa-balance-scale' },
    { name: 'Education', subCategories: ['Schools', 'Colleges', 'Universities'], icon: 'fa-school' },
    { name: 'Health', subCategories: ['Hospitals', 'Clinics', 'Pharmacies'], icon: 'fa-clinic-medical' },
    { name: 'Student Services', subCategories: ['Counseling', 'Tutoring', 'Mentoring'], icon: 'fa-hands-helping' },
    { name: 'Beauty', subCategories: ['Salons', 'Spas', 'Nail Studios'], icon: 'fa-spa' },
    { name: 'Taylor', subCategories: ['Men', 'Women', 'Kids'], icon: 'fa-user' },
    { name: 'Storage', subCategories: ['Personal', 'Business', 'Vehicle'], icon: 'fa-warehouse' },
    { name: 'Translation', subCategories: ['Documents', 'Interpretation', 'Localization'], icon: 'fa-language' },
    { name: 'Venues', subCategories: ['Wedding', 'Conference', 'Party'], icon: 'fa-church' },
    { name: 'Worship Places', subCategories: ['Church', 'Temple', 'Mosque'], icon: 'fa-place-of-worship' },
    { name: 'Entertainment', subCategories: ['Movies', 'Concerts', 'Theater'], icon: 'fa-film' },
    { name: 'Employment', subCategories: ['Full-Time', 'Part-Time', 'Freelance'], icon: 'fa-briefcase' },
    { name: 'Financial', subCategories: ['Banking', 'Insurance', 'Investment'], icon: 'fa-money-check' },
    { name: 'Communication', subCategories: ['Phone', 'Internet', 'Mail'], icon: 'fa-phone' },
    { name: 'Sports', subCategories: ['Football', 'Basketball', 'Tennis'], icon: 'fa-football-ball' }
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div className="main-categories-wrapper">
      <div className="big-category-tile">
        <h2 className="product-service-header">Products</h2>
        <Slider {...sliderSettings}>
          {productCategories.map((category, index) => (
            <div key={index} className="category">
              <div className="category-icon"><i className={`fas ${category.icon}`}></i></div>
              <span className="category-name">{category.name}</span>
            </div>
          ))}
        </Slider>
      
        <h2 className="product-service-header">&nbsp;</h2>
      </div>

      <div className="big-category-tile">
        <h2 className="product-service-header">Services</h2>
        <Slider {...sliderSettings}>
          {serviceCategories.map((category, index) => (
            <div key={index} className="category">
              <div className="category-icon"><i className={`fas ${category.icon}`}></i></div>
              <span className="category-name">{category.name}</span>
            </div>
          ))}
        </Slider>
        <h2 className="product-service-header">&nbsp;</h2>
      </div>

      <div className="ad-section">
        
        <span style={{ fontWeight: 'bold' }} className="adjustable-text">Want to sell your Goods & Services?</span>
        <span className="adjustable-text">You are at the Right Place! At our platform, you can buy or sell Goods & Services to the user via posting an AD</span>
    
        <button className="explore-button">Explore</button>
        <button className="post-ad-button">Post an Ad</button>
      </div>
    </div>
  );
};

export default MainCategories;
