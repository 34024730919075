import React, { useState } from 'react';
import './Shahi.css';

const Shahi = () => {
  const [couponCopied, setCouponCopied] = useState(false);

  const handleCopyCoupon = () => {
    navigator.clipboard.writeText('SHIPFREE');
    setCouponCopied(true);
    setTimeout(() => setCouponCopied(false), 3000); // Hide the message after 3 seconds
  };

  return (
    <div className="shahi-container">
      <div className="header">
        <div className="header-top">
          <h1>Your Business Name</h1>
          <div className="social-container">
            <p className="social-text"><b>www.GoOurHub.com/YourBusiness</b></p>
            <div className="social-icons">
              <a href="https://www.instagram.com"><img src="/instagram.png" alt="Instagram" className="social-icon" /></a>
              <a href="https://www.facebook.com"><img src="/facebook.png" alt="Facebook" className="social-icon" /></a>
              <a href="https://www.tiktok.com"><img src="/tiktok.png" alt="TikTok" className="social-icon" /></a>
            </div>
          </div>
        </div>
        <div className="contact-info">
          <p>Located in: Stonehue Center | 203## Huebner Rd, San Antonio, TX 78298 | Phone: (210) 481-2O2S</p>
        </div>
        <div className="scroll-text">
          <p>Hours: Every Day: 10 AM–10 PM</p>
        </div>
      </div>
      <div className="gallery-box">
        <h3>Gallery</h3>
        <div className="gallery">
          <img src="/shahiImage1.jpg" alt="Shahi Storefront" />
          <img src="/shahiImage2.jpg" alt="Interior" />
          <img src="/shahiImage3.jpg" alt="Products" />
          <img src="/shahiImage4.jpg" alt="Jewelry" />
        
        </div>
      </div>
      <div className="content-section no-border-shadow">
        <div className="box">
          <h3> <span className="fancy-header">About Us</span></h3>
          <p>Here description about your business goes. Example: Our Business is a small business located in San Antonio, TX. We offer a wide variety of products, 
            .</p>
        </div>
        <div className="box">
          <h3><span className="fancy-header">Products</span></h3>
          <p>We offer a variety of products including - Mention examples of your products here.</p>
          <div className="product-items">
            <div className="product-item">
              Item 1
              <button onClick={() => { alert('Item 1 added to cart') }}>Add to Cart</button>
            </div>
            <div className="product-item">
              Item 2
              <button onClick={() => { alert('Item 2 added to cart') }}>Add to Cart</button>
            </div>
          </div>
        </div>
        <div className="box">
          <h3 className="flashing-offers">Special Offers</h3>
          <div className="coupon-box">
            <p>This week only</p>
            <p>Free shipping on your first order. Use a coupon code: SHIPFREE</p>
            <button onClick={handleCopyCoupon}>COPY COUPON</button>
            {couponCopied && <p className="copied-message">Coupon copied!</p>}
            <p>Coupon valid until the end of the week</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shahi;
